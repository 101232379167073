import { useParams } from "react-router-dom";
import { db } from "../firebase/FirebaseClient";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import IndexDetail from "../components/IndexDetail";
import configData from "../config/default.json";
import UserStatus from "../components/UserStatus";
// import AuthStatus from "../components/ui/AuthStatus";
export default function Wellbeing() {
  const { id } = useParams();
  // console.log("id : [", id, "]");
  const [indexData, setIndexData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const documentIndexRef = doc(db, configData.partials, "documentIndex");
    // console.log("configData.partials : [", configData.partials, "]");
    const getDocumentIndexData = async () => {
      const docSnap = await getDoc(documentIndexRef);
      if (docSnap.exists()) {
        // console.log('Document data:', docSnap.data());
        setIndexData(docSnap.data().payload);
      } else {
        console.log("ERROR : Wellbeing - No document found!");
        setError("content not available");
      }
    };
    getDocumentIndexData();

    window.scrollTo(0, 0);
  }, [id]);

  return (
    <div className="container-lg my-5">
      <div className="">
        {error && (
          <>
            <p className="error text-center">{error}</p>
            <UserStatus />
          </>
        )}

        {indexData && <IndexDetail indexData={indexData} urlParams={id} />}
        {!indexData && (
          <>
            <p className="error text-center">loading...</p>
          </>
        )}
      </div>
    </div>
  );
}
