import React from "react";
import { store } from "./redux-toolkit/store";
import { Provider } from "react-redux";
import Navbar from "./components/Navbar";
import { BrowserRouter } from "react-router-dom";
import AnimatedRoutes from "./components/AnimatedRoutes";
import configData from "./config/default.json";

import "./AppStyle.css";

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <div className="">
          <BrowserRouter>
            <Navbar />
            <div className="">
              <AnimatedRoutes />
            </div>
            <footer className="p-3 text-xs text-center  text-secondary">
              <p>NST Wellbeing Coach App &copy; {new Date().getFullYear()}.</p>
              {/* <p>{configData.version}</p> */}
            </footer>
          </BrowserRouter>
        </div>
      </div>
    </Provider>
  );
}

export default App;
