import React from "react";
// import TSCsAndCs from "../components/TSCsAndCs";
import EndUserLicenceAgreement from "../components/EndUserLicenceAgreement";
import configData from "../config/default.json";
import { motion } from "framer-motion";
import PageBanner from "../components/card/PageBanner";

export default function Eula() {
  const pageMotion = configData.pageMotionMenu;

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={pageMotion}
    >
      {/* container */}
      <div className="m-5">
        {/* card - page banner */}
        {/* <PageBanner text={"EULA"} /> */}

        <div className="">
          <EndUserLicenceAgreement />
        </div>
      </div>
    </motion.div>
  );
}
