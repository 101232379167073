import { useLocation, Routes, Route } from "react-router-dom";

import Wellbeing from "../pages/Wellbeing";
import About from "../pages/About";
import Home from "../pages/Home";
import Guide from "../pages/Guide";
import AskTheCoach from "../pages/AskTheCoach";
import Logout from "../pages/Logout";
import Login from "../pages/Login";
import Blog from "../pages/Blog";
import BlogEntry from "../pages/BlogEntry";
import FAQ from "../pages/FAQ";
import TSandCs from "../pages/TSandCs";
import Subscription from "../pages/Subscription";
import Tips from "../pages/Tips";
import Founder from "../pages/Founder";
import Eula from "../pages/EULA";
// import Privacy from "../pages/PrivacyPolicy";
import { useEffect } from "react";
import PrivacyPolicy from "../pages/PrivacyPolicy";
// import { useLocation } from "react-router-dom";

const AnimatedRoutes = () => {
  const location = useLocation();




  // const location = useLocation();

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
    });
  }, [location]);


  return (
    <Routes location={location} key={location.pathname}>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/tips" element={<Tips />} />
      <Route path="/wellbeing" element={<Wellbeing />} />
      <Route path="/wellbeing/:id" element={<Wellbeing />} />
      <Route path="/about" element={<About />} />
      <Route path="/founder" element={<Founder />} />
      <Route path="/askthecoach" element={<AskTheCoach />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/blogentry/:id" element={<BlogEntry />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/login" element={<Login />} />
      <Route path="/guide" element={<Guide />} />
      <Route path="/faq" element={<FAQ />} />
      <Route path="/tsandcs" element={<TSandCs />} />
      <Route path="/subscription" element={<Subscription />} />
      <Route path="/eula" element={<Eula />} />
      <Route path="/privacypolicy" element={<PrivacyPolicy />} />
    </Routes>
  );
};

export default AnimatedRoutes;
